import React from "react";
// import "./styles.css";

export default function Card({ children, title }) {
  const styles = {
    container: {
      borderRadius: 10,
      border: "1px solid #55555555",
      margin: "10px auto",
      backgroundColor: "#eee",
      color: "black",
      padding: 10,
      width: "100%",
      maxWidth: "770px",
    },
    separators: {
      borderColor: "#55555555",
      width:"100%"
    },
    body: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <h2>{title}</h2>
      <hr style={styles.separators} />
      <div style={styles.body}>{children}</div>
    </div>
  );
}
