import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';

export default function DataList({ array, forInput, label, returnValue = () => {} }) {
    const [docRef, setDoc] = useState(document.getElementById(forInput))
    const [labelRef, setLabelRef] = useState(document.getElementById(label))
    const [visibility, setVisibility] = useState(false);
    const [filter, setFilter] = useState("");
    const [filteredArray, setFilteredArray] = useState(array);


    useEffect(() => {
        setDoc(document.getElementById(forInput))
        setLabelRef(document.getElementById(label))
        const handleClick = (e) => {
            // console.log(docRef)
            if (e.target === document.getElementById(forInput) || e.target === document.getElementById(label)) {
                setVisibility(true);
            } else {
                setVisibility(false);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const addItemToInput = (item) => {
        const newValue = docRef.value ? `${docRef.value}, ${item}` : item;
        // console.log(docRef)z
        returnValue(newValue);
    };

    const handleInputChange = (e) => {
        setFilter(e.currentTarget.value.trim());
    };

    useEffect(() => {
        setFilteredArray(array.filter(item => item.toLowerCase().includes(filter.toLowerCase())));
    }, [array, filter]);

    return (
        <div style={{ ...styles.dataList, display: visibility ? "flex" : "none" }} className="dataList">
            {filteredArray.map((item, index) => (
                <span key={index} onClick={() => addItemToInput(item)}>{item}</span>
            ))}
        </div>
    );
}

DataList.propTypes = {
    array: PropTypes.arrayOf(PropTypes.string).isRequired,
    forInput: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    returnValue: PropTypes.func
};

const styles = {
    dataList: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        backgroundColor: "#fff",
        borderRadius: 5,
        transform: "translateY(45px)",
        boxShadow: "0px 0px 5px #00000050",
        maxHeight: "300px",
        overflowY: "scroll",
    },
};
