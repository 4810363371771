import React, {useEffect} from "react";

export default function Home({setPage}){

    useEffect(()=>{
        setPage("home")
    },[setPage])
    return(
        <div><h1>Home sweet home</h1></div>
    )
}