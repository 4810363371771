// Sidebar.tsx

import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faGripVertical,
    faHome

} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({page}) => {

  const [isCreateOption, setCreateOption] = useState(false)
  return (
    <div className="sidebar">
      <Link className={`navLinks ${page === "home" ? 'current':''}`} to="/">
        <FontAwesomeIcon icon={faHome} color='white' size='sm'/>
        <p>Home</p>
      </Link>
      <Link className={`navLinks ${page === "projects" ? 'current':''}`} to="/projects">
        <FontAwesomeIcon FontAwesomeIcon icon={faGripVertical} color='white' size='sm'/>
        <p>Projects</p>
      </Link>
      <hr></hr>
      <button onClick={()=>{setCreateOption(true)}} onMouseLeave={()=>{setCreateOption(false)}} className={`createNav navLinks ${page === "create" ? 'current':''}`}>
        <FontAwesomeIcon icon={faAdd} color='white' size='sm'/>
        <p>Create</p>
        <div className='navOptions' style={{display: isCreateOption ? '' : 'none'}}>
        <Link  to="/create/tv">TV Shows</Link>
        <Link  to="/create/movies">Movies</Link>
        <Link  to="/create/music">Music</Link>
        </div>
      </button>
    </div>
  );
};

export default Sidebar;
