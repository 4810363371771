import React, { createContext, useState, useEffect, useContext } from "react";
import { database, auth } from "../firebase";
import { ref, onValue } from "@firebase/database";

import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  UserCredential
} from "@firebase/auth";


function getUser(uid, onResult = (user) => {}) {
  const starCountRef = ref(database, "users/" + uid);
  onValue(starCountRef, (snapshot) => {
    const user = snapshot.val();
    onResult(user);
  });
}



  // Function to handle user authentication
  const signup = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    signOut(auth);
  };

const initValue = {
  isAuthenticated: false,
  user: null,
  signup: signup,
  login: login,
  logout: logout,
};


// Create the context
const AuthContext = createContext(initValue);

// Create a functional component to provide the context
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user)
      getUser(user?.uid, (e) => {
        setUser({...e, uid: user?.uid|| null});
        setIsAuthenticated(true);
      });
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, signup, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useAuth = () => useContext(AuthContext);
