import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from "./firebase";
import React,{useState, useEffect} from "react";

export function Upload(file, postKey, name, labelId, set) {
    const storageRef = ref(storage, 'contents/' + postKey + "/" + name);
    // const storageRef = ref(storage, 'test/' + postKey + "/" + name);

    const doc = document.getElementById(labelId);
    doc.style.borderColor = `rgb(93 180 255)`;
    doc.style.borderStyle = "solid"
    doc.style.justifyContent = "flex-end"

    const progressId = labelId.replace('Label', 'Progress')
    const cancelId = labelId.replace('Label', 'Cancel')



    doc.innerHTML = `
    <div id="${progressId}" style=" margin-right: auto; height: 100%; background-color:#0090ff; width:0%; display:flex; align-items:center; justify-content: center;">
    <p>0%</p>
    </div>
    <button id="${cancelId}" style="margin: 10px; border-radius: 5px; border: 2px solid #fff; cursor: pointer; background-color: #fff; position: absolute; float: right;">cancel</button>
    `


    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on('state_changed',
        (snapshot) => {
            const progressBar = document.getElementById(progressId)
            
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case 'paused':
                    progressBar.style.backgroundColor = "orange"
                    progressBar.querySelector('p').innerText = `Paused at ${progress.toFixed()}%`
                    break;
                case 'running':
                    progressBar.style.backgroundColor = "#0090ff"
                    progressBar.querySelector('p').innerText = `Uploading ${progress.toFixed()}%`
                    progressBar.style.width = `${progress}%`
                    break;
            }
        },
        (error) => {
            // Handle unsuccessful uploads
            const progressBar = document.getElementById(progressId)
            progressBar.style.backgroundColor = "#aa0000"
            progressBar.innerText = `${error.code.replace('storage/', '')}`
        },
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const progressBar = document.getElementById(progressId)
                progressBar.style.backgroundColor = "#00aa00"
                progressBar.innerText = `Done`
                doc.style.borderColor = "rgb(101 191 101)"
                document.getElementById(cancelId)?.remove()
                set(downloadURL);
            });
        })

        document.getElementById(cancelId).onclick = (e)=> {
            e.stopPropagation()
            uploadTask.cancel();
            document.getElementById(cancelId).remove()
        }
}

export function UploadWithPreview(file, postKey, name, labelId, set) {
    const storageRef = ref(storage, 'contents/' + postKey + "/" + name);

    const doc = document.getElementById(labelId);
    doc.style.borderColor = `rgb(93 180 255)`;
    doc.style.borderStyle = "solid"
    doc.style.justifyContent = "flex-start"

    const progressId = labelId.replace('Label', 'Progress')
    const cancelId = labelId.replace('Label', 'Cancel')

    doc.innerHTML = `
    <div id="${progressId}" style=" margin-right: auto; height: 100%; background-color:#0090ff; width:0%; display:flex; align-items:center; justify-content: center;">
    <p>0%</p>
    </div>
    <button id="${cancelId}" style="margin: 10px; border-radius: 5px; border: 2px solid #fff; cursor: pointer; background-color: #fff; position: absolute; float: right;">cancel</button>
    `
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on('state_changed',
        (snapshot) => {
            const progressBar = document.getElementById(progressId)
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case 'paused':
                    progressBar.style.backgroundColor = "orange"
                    progressBar.querySelector('p').innerText = `Paused at ${progress.toFixed()}%`
                    break;
                case 'running':
                    progressBar.style.backgroundColor = "#0090ff"
                    progressBar.querySelector('p').innerText = `Uploading ${progress.toFixed()}%`
                    progressBar.style.width = `${progress}%`
                    break;
            }
        },
        (error) => {
            // Handle unsuccessful uploads
            const progressBar = document.getElementById(progressId)
            progressBar.style.backgroundColor = "#aa0000"
            progressBar.innerText = `Error uploading ${error.code}`
        },
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const progressBar = document.getElementById(progressId)
                progressBar.style.backgroundColor = "transparent"
                progressBar.innerText = `Done`
                doc.style.borderColor = "transparent"
                // const doc = document.getElementById("LandscapePosterLabel");
                doc.style.backgroundImage = `url(${downloadURL})`;
                set(downloadURL);
                document.getElementById(cancelId)?.remove()
            });
        })

        document.getElementById(cancelId).onclick = (e)=> {
            e.stopPropagation()
            uploadTask.cancel();
            document.getElementById(cancelId).remove()
        }
}



export function UploadMultiple(files, postKey, dirname, labelId, set) {

    const doc = document.getElementById(labelId);
    doc.style.borderColor = `rgb(93 180 255)`;
    doc.style.borderStyle = "solid"
    doc.style.justifyContent = "flex-start"

    const array = []

    // Display initial progress bar
    const progressId = labelId.replace('Label', 'Progress');
    const cancelId = labelId.replace('Label', 'Cancel')

    doc.innerHTML = `
    <div id="${progressId}" style=" margin-right: auto; height: 100%; background-color:#0090ff; width:0%; display:flex; align-items:center; justify-content: center;">
    <p>0%</p>
    </div>
    <button id="${cancelId}" style="margin: 10px; border-radius: 5px; border: 2px solid #fff; cursor: pointer; background-color: #fff; position: absolute; float: right;">cancel</button>
    `
    let totalBytes = 0;
    let uploadedBytes = 0;

    Object.values(files).forEach(file => {
        const storageRef = ref(storage, 'contents/' + postKey + "/" + dirname + '/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progressBar = document.getElementById(progressId);
                totalBytes += snapshot.totalBytes;
                uploadedBytes += snapshot.bytesTransferred;

                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                    case 'paused':
                        updateProgress(progressBar, `Paused at ${progress.toFixed()}%`, "orange");
                        break;
                    case 'running':
                        updateProgress(progressBar, `Uploading ${progress.toFixed()}%`, "#0090ff", progress);
                        break;
                }
            },
            (error) => {
                const progressBar = document.getElementById(progressId);
                updateProgress(progressBar, `Error uploading ${error.code}`, "#aa0000");
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const progressBar = document.getElementById(progressId);
                    updateProgress(progressBar, `Done`, "#00aa00");
                    doc.style.borderColor = "rgb(101 191 101)";
                    console.log('uploaded file: ', uploadTask.snapshot.metadata.name);
                    array.push({ label: file.name, src: downloadURL })
                    document.getElementById(cancelId)?.remove()
                });
            });

            document.getElementById(cancelId).onclick = (e)=> {
                e.stopPropagation()
                uploadTask.cancel();
                document.getElementById(cancelId)?.remove()
            }
    });

    function updateProgress(progressBar, text, color, width) {
        progressBar.style.backgroundColor = color;
        progressBar.querySelector('p').innerText = text;
        if (width) {
            progressBar.style.width = `${width}%`;
        }
    }

    set(array);
}
