// App.tsx

import React, { useEffect, useState } from 'react';
import "./App.css"
import { AuthProvider, useAuth } from './context/AuthContext';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from './pages/Login';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import CreateMovie from './pages/MoviePublishing';

const App = () => {
  const [page, setPage] = useState()

  const { user, isAuthenticated } = useAuth()

  useEffect(() => {
    console.log(user)
  }, [user])


  return (
    <AuthProvider>
      <BrowserRouter>
        {/* {user ? ( */}
          <div className="app">
            <Sidebar page={page} />
            <div className="content">
              <Routes>
                <Route path="/" exact element={<Home setPage={setPage} />} />
                <Route path="/projects" element={<Projects setPage={setPage} />} />
                <Route path="/create/movies" element={<CreateMovie setPage={setPage} />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </div>
          </div>
        {/* ) : (
          <div className="app">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </div>

        )} */}
      </BrowserRouter>
    </AuthProvider>

  );
};

export default App;
