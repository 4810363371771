import react, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../components/card";
import { ref, child, push, update, set } from "@firebase/database";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { database, auth } from "../firebase";
import { Upload, UploadWithPreview, UploadMultiple } from "../Uploads";
import Message from "../components/message";
import DataList from "../components/DataList";

// signInWithEmailAndPassword(auth, "sterly.dolce@gmail.com", "Sterly200534");

// console.log(auth.currentUser.email)


function generateUniqueId() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

// console.log(generateUniqueId());



export default function CreateMovie({ setPage }) {

  const [ContentRef, setContentRef] = useState(ref(database, 'contents/'));
  const [newPostKey, setNewPostKey] = useState();


  useEffect(() => {
    setPage("create")

  }, [setPage])

  useEffect(() => {
    setNewPostKey(push(ContentRef).key);
  }, [])


  const [PortraitPoster, setPortraitPoster] = useState();
  const [LandscapePoster, setLandscapePoster] = useState();
  const [Logo, setLogo] = useState();
  const [LandscapeBackdrop, setLandscapeBackdrop] = useState();
  const [WideBackdrop, setWideBackdrop] = useState();

  const [title, setTitle] = useState("");
  const [overview, setOverview] = useState("");
  const [genres, setGenres] = useState("");
  const [keywords, setKeywords] = useState();
  const [Length, setLength] = useState();
  const [productionCompany, setProductionCompany] = useState();
  const [copyrightLine, setCopyrightLine] = useState();
  const [countries, setCountries] = useState();
  const [released, setReleased] = useState();


  const [rating, setRating] = useState();

  const [directors, setDirectors] = useState();
  const [writers, setWriters] = useState();
  const [producers, setProducers] = useState();
  const [composers, setComposers] = useState();
  const [casts, setCasts] = useState([]);

  const [isFree, setIsFree] = useState(false);
  const [price, setPrice] = useState(99);

  const [Trailer, setTrailer] = useState();
  const [TrailerCaption, setTrailerCaption] = useState([]);
  const [MainVideo, setMainVideo] = useState();
  const [MainVideoCaption, setMainVideoCaption] = useState([]);

  const [rightToContent, setRightToContent] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const [isReady, setReady] = useState(false)

  const [notif, setNotif] = useState(null)
  const [notifTime, setNotifTime] = useState(5000)

  useEffect(() => {
    if (rightToContent == true && agreement == true) {
      setReady(true)
    } else {
      setReady(false)
    }
  }, [rightToContent, agreement])

  function addActor() {
    setCasts([...casts, { actor: "", character: "" }]);
  }

  function removeActor(index) {
    setCasts(casts.filter((_, i) => i !== index));
  }

  function updateActor(index, actor, character) {
    const updatedCasts = casts.map((item, i) => {
      if (i === index) {
        return { actor, character };
      }
      return item;
    });
    setCasts(updatedCasts);
  }

  function updatePortraitPoster(file) {
    UploadWithPreview(file, newPostKey, 'portraitPoster', 'PortraitPosterLabel', setPortraitPoster)

  }

  function updateLandscapePoster(file) {
    UploadWithPreview(file, newPostKey, 'landscapePoster', 'LandscapePosterLabel', setLandscapePoster)
  }

  function updateLogo(file) {
    UploadWithPreview(file, newPostKey, 'logo', 'LogoLabel', setLogo)
  }

  function updateLandscapeBackdrop(file) {
    UploadWithPreview(file, newPostKey, 'landscapeBackdrop', 'LandscapeBackdropLabel', setLandscapeBackdrop)
  }

  function updateWideBackdrop(file) {
    UploadWithPreview(file, newPostKey, 'WideBackdrop', 'WideBackdropLabel', setWideBackdrop)

  }

  function updateTrailer(file) {
    const doc = document.getElementById("trailerLabel");

    Upload(file, newPostKey, 'trailer', 'trailerLabel', setTrailer)
  }

  function updateTrailerCaption(files) {

    UploadMultiple(files, newPostKey, 'trailerCaptions', 'trailerCaptionLabel', setTrailerCaption)
  }

  function updateMainVideo(file) {
    Upload(file, newPostKey, 'main', 'mainVideoLabel', setMainVideo)
  }

  function updateMainVideoCaption(files) {

    UploadMultiple(files, newPostKey, 'captions', 'mainCaptionLabel', setMainVideoCaption)
  }



  function done() {
    const content = {
      captions: MainVideoCaption.map((item) => { return { label: item.label.replace(/\./g, ' ').replace('srt', '').trim(), src: item.src } }),
      trailer_captions: TrailerCaption.map((item) => { return { label: item.label.replace(/\./g, ' ').replace('srt', '').trim(), src: item.src } }),
      trailerUrl: Trailer,
      videoUrl: MainVideo,
      title: title,
      overview: overview,
      backdrop: LandscapeBackdrop,
      wide_backdrop: WideBackdrop,
      logo: Logo,
      poster: PortraitPoster,
      thumbnail: LandscapePoster,
      credit: {
        casts: casts,
        directors: directors,
        writers: writers,
        producers: producers,
        composers: composers
      },
      free: isFree,
      price: price,
      uniqueId: generateUniqueId(),
      rating: rating,
      genres: genres.split(','),
      status: "Published",
      content_type: "Film",
      released: released,
      id: newPostKey,
      length: Length,
      keywords: keywords,
      production: {
        production_company: productionCompany,
        copyright_line: copyrightLine,
        countries: countries
      }
    }



    try {

      const updates = {};
      updates[`${newPostKey}/`] = content;

      // console.log(content)

      setNotifTime(5000)
      setNotif({
        title: "Upload success: ",
        body: `Done, You can now close this screen`,
        type: "succeed"
      })

      setTimeout(() => {
        setNotif(null)
      }, notifTime);

      return update(ContentRef, updates);

    } catch (error) {

      for (const key in content) {
        if (content.hasOwnProperty(key) && content[key] === undefined) {
          console.log(`Value of ${key.replace('Url', '')} is blank`);
          setNotifTime(5000)
          setNotif({
            title: "Missing: ",
            body: `Value of ${key.replace('Url', '')} is blank`,
            type: "error"
          })

          setTimeout(() => {
            setNotif(null)
          }, notifTime);
        }
      }

    }
  }

  const genresArray = [
    "Action/Adventure",
    "Animation",
    "Comedy",
    "Crime",
    "Documentary",
    "Drama",
    "Fantasy",
    "Horror",
    "Informational & Education",
    "Musical/Dance",
    "Musical & Performance",
    "Mystery",
    "Reality Show",
    "Romance",
    "Sci-Fi",
    "Sport & Fitness",
    "Thriller",
    "War",
    "Western"
  ];

  return (
    <div style={styles.container}>
      {/* artworks */}
      <Card title="Artworks">
        <input
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          id="PortraitPoster"
          onChange={(e) => updatePortraitPoster(e.currentTarget.files[0])}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          id="LandscapePoster"
          onChange={(e) => updateLandscapePoster(e.currentTarget.files[0])}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          id="Logo"
          onChange={(e) => updateLogo(e.currentTarget.files[0])}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          id="LandscapeBackdrop"
          onChange={(e) => updateLandscapeBackdrop(e.currentTarget.files[0])}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          id="WideBackdrop"
          onChange={(e) => updateWideBackdrop(e.currentTarget.files[0])}
        />

        <label
          style={{ ...styles.posters, aspectRatio: "2/3" }}
          id="PortraitPosterLabel"
          htmlFor="PortraitPoster"
        >
          Portrait 2:3 Poster
        </label>
        <label
          style={{ ...styles.posters, aspectRatio: "16/9" }}
          id="LandscapePosterLabel"
          htmlFor="LandscapePoster"
        >
          Landscape 16:9 Poster
        </label>
        <label
          style={{
            ...styles.posters,
            aspectRatio: "3/2",
            height: "195px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          id="LogoLabel"
          htmlFor="Logo"
        >
          Logo
        </label>
        <label
          style={{ ...styles.posters, aspectRatio: "16/9" }}
          id="LandscapeBackdropLabel"
          htmlFor="LandscapeBackdrop"
        >
          Landscape 16:9 Backdrop
        </label>
        <label
          style={{ ...styles.posters, aspectRatio: "3/1" }}
          id="WideBackdropLabel"
          htmlFor="WideBackdrop"
        >
          Wide 3:1 Backdrop
        </label>
      </Card>
      {/* Videos */}
      <Card title="Videos">
        <input
          type="file"
          style={{ display: "none" }}
          accept="video/mp4"
          id="trailer"
          onChange={(e) => updateTrailer(e.currentTarget.files[0])}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="video/mp4"
          id="mainVideo"
          onChange={(e) => updateMainVideo(e.currentTarget.files[0])}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="caption/*"
          multiple={true}
          id="trailerCaption"
          onChange={(e) => updateTrailerCaption(e.currentTarget.files)}
        />
        <input
          type="file"
          style={{ display: "none" }}
          accept="caption/*"
          multiple={true}
          id="mainCaption"
          onChange={(e) => updateMainVideoCaption(e.currentTarget.files)}
        />
        <label
          style={{ ...styles.posters, aspectRatio: "10", height: "50px" }}
          id="trailerLabel"
          htmlFor="trailer"
        >
          Trailer
        </label>
        <label
          style={{ ...styles.posters, aspectRatio: "5", height: "50px" }}
          id="trailerCaptionLabel"
          htmlFor="trailerCaption"
        >
          Caption
        </label>
        <label
          style={{ ...styles.posters, aspectRatio: "10", height: "50px" }}
          id="mainVideoLabel"
          htmlFor="mainVideo"
        >
          {/* <div style={styles.progress}></div> */}
          Main Video
        </label>
        <label
          style={{ ...styles.posters, aspectRatio: "5", height: "50px" }}
          id="mainCaptionLabel"
          htmlFor="mainCaption"
        >
          Caption
        </label>
        <button style={{ ...styles.buttons }}>Edit Chapters</button>
      </Card>
      {/* About */}
      <Card title="About">
        <input placeholder="Title*" value={title} onChange={(e) => { setTitle(e.currentTarget.value) }} style={{ ...styles.input }} />
        <textarea
          placeholder="Overview*"
          style={{ ...styles.input, height: "150px", resize: "none" }}
          value={overview} onChange={(e) => { setOverview(e.currentTarget.value) }}
        />
        <input placeholder="Length (eg: 1h 30min)" value={Length} onChange={(e) => { setLength(e.currentTarget.value) }} style={{ ...styles.input }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            width: "100%",
          }}
        >
          <label htmlFor="genres" id="genresLabel" style={{ ...styles.input, justifyContent: "flex-start", display: "flex", gap: 5, fontSize: 12, backgroundColor: "#fff" }}>
            <>
              {genres[0] ? (
                <>
                  {genres.split(',').map((genre, index) => (
                    <>
                      {genre.trim().length > 0 && (
                        <span key={index} style={styles.spans}>{genre.trim()}</span>
                      )}
                    </>
                  ))}
                </>
              ):(
              <span>Genres</span>
            )}
            </>


          </label>
          <input placeholder="Genres*" id="genres" list="genreList" value={genres} onChange={(e) => { setGenres(e.currentTarget.value) }} style={{ transform: "scale(0)", position: "absolute" }} />
          <DataList forInput="genres" array={genresArray} returnValue={(e) => setGenres(e)} label="genresLabel" />
          <input placeholder="Keywords" value={keywords} onChange={(e) => { setKeywords(e.currentTarget.value) }} style={{ ...styles.input }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            width: "100%",
          }}
        >
          <input
            placeholder="Production Company*"
            style={{ ...styles.input }}
            value={productionCompany} onChange={(e) => { setProductionCompany(e.currentTarget.value) }}
          />
          <input
            placeholder="Copyright Line"
            style={{ ...styles.input }}
            value={copyrightLine} onChange={(e) => { setCopyrightLine(e.currentTarget.value) }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            width: "100%",
          }}
        >
          <input placeholder="Countries" value={countries} onChange={(e) => { setCountries(e.currentTarget.value) }} style={{ ...styles.input }} />
          <input type="date" value={released} onChange={(e) => { setReleased(e.currentTarget.value) }} placeholder="Date" style={{ ...styles.input }} />
        </div>
      </Card>

      {/* Ratings */}
      <Card title="Ratings">
        <select
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          style={{ ...styles.input }}
        >
          <option value="">Select Rating</option>
          <option value="G">G - General Audiences</option>
          <option value="PG">PG - Parental Guidance Suggested</option>
          <option value="PG-13">PG-13 - Parents Strongly Cautioned</option>
          <option value="R">R - Restricted</option>
          <option value="NC-17">NC-17 - Adults Only</option>
          {/* Add more rating options as needed */}
        </select>
      </Card>

      {/* Cast & Crew */}
      <Card title="Cast & Crew">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            width: "100%",
          }}
        >
          <input
            placeholder="Writers*"
            style={{ ...styles.input }}
            value={writers} onChange={(e) => { setWriters(e.currentTarget.value) }}
          />
          <input
            placeholder="Directors*"
            style={{ ...styles.input }}
            value={directors} onChange={(e) => { setDirectors(e.currentTarget.value) }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            width: "100%",
          }}
        >
          <input
            placeholder="Producers"
            style={{ ...styles.input }}
            value={producers} onChange={(e) => { setProducers(e.currentTarget.value) }}
          />
          <input
            placeholder="Composers"
            style={{ ...styles.input }}
            value={composers} onChange={(e) => { setComposers(e.currentTarget.value) }}
          />
        </div>
        {casts.map((cast, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              width: "100%",
            }}
          >
            <p>{index}</p>
            <input
              placeholder="Actor*"
              value={cast.actor}
              style={{ ...styles.input }}
              onChange={(e) =>
                updateActor(index, e.currentTarget.value, cast.character)
              }
            />
            <input
              placeholder="Character*"
              value={cast.character}
              style={{ ...styles.input }}
              onChange={(e) =>
                updateActor(index, cast.actor, e.currentTarget.value)
              }
            />
            <button
              style={{ ...styles.buttons }}
              onClick={() => removeActor(index)}
            >
              Remove
            </button>
          </div>
        ))}

        <button style={{ ...styles.buttons }} onClick={addActor}>
          Add Actor
        </button>
      </Card>
      {/* Earnings */}
      <Card title="Earnings">
        <label>
          <input
            type="checkbox"
            name="isFree"
            checked={isFree}
            onChange={(e) => setIsFree(e.currentTarget.checked)}
          />
          Free?
        </label>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <select disabled={isFree} value={price} onChange={(e) => { setPrice(e.currentTarget.value) }} style={{ ...styles.input }}>
            <option value="99">$0.99</option>
            <option value="199">$1.99</option>
            <option value="599">$5.99</option>
            <option value="1099">$10.99</option>
          </select>
        </div>
      </Card>

      {/* Agreements */}
      <Card title="Agreements">
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <input checked={rightToContent} onChange={(e) => { setRightToContent(e.currentTarget.checked) }} type="checkbox" id="contentMine" />
          <label htmlFor="contentMine">
            I agree that this content is 100% mine
          </label>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <input checked={agreement} onChange={(e) => { setAgreement(e.currentTarget.checked) }} type="checkbox" id="termsConditions" />
          <label htmlFor="termsConditions">
            I agree to the terms and conditions
          </label>
        </div>
        {/* Publish Button */}
        <div style={{ marginTop: "20px" }}>
          <button onClick={done} disabled={!isReady} style={{ ...styles.buttons }}>
            Publish
          </button>
        </div>
      </Card>
      {notif && (
        <Message type={notif.type} title={notif.title} body={notif.body} />
      )}

    </div>
  );
}

const styles = {
  container: {},
  card: {
    fontSize: "18px",
  },
  title: {},
  posters: {
    height: "250px",
    border: "2px dashed #55555555",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    overflow: "hidden"
  },
  input: {
    padding: "10px 15px ",
    border: "2px solid #55555555",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttons: {
    border: "2px solid #ffffff50",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 20px ",
    backgroundColor: "#0090ff",
    color: "white",
    cursor: "pointer",
  },

  progress: {
    height: "100%",
    width: "50%",
    backgroundColor: "#0090ff"
  },

  spans: {
    backgroundColor: "#0090ff",
    borderRadius: 5,
    padding: "5px 10px",
    fontSize: "10px"
  },
};
