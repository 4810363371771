import React, {useEffect} from "react";

export default function Projects({setPage}){

    useEffect(()=>{
        setPage("projects")
    },[setPage])
    return(
        <div><h1>Home sweet adr</h1></div>
    )
}